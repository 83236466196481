define("discourse/plugins/discourse-topic-management/discourse/routes/archived-topics", ["exports", "discourse/routes/discourse", "rsvp", "discourse/plugins/discourse-topic-management/discourse/models/archived-topics"], function (_exports, _discourse, _rsvp, _archivedTopics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      if (!this.currentUser) {
        return {
          error: "not_logged_in"
        };
      }
      const user = this.modelFor("user");
      const settings = this.siteSettings;
      return (0, _rsvp.all)([_archivedTopics.default.list(user.username)]);
    },
    setupController(controller, model) {
      if (model.error) {
        controller.setProperties({
          error: model.error
        });
      } else {
        controller.setProperties({
          archivedTopics: model[0]
        });
      }
    },
    actions: {
      refreshRoute() {
        this.refresh();
      }
    }
  });
});