define("discourse/plugins/discourse-topic-management/discourse/templates/connectors/topic-list-after-title/topic", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.limit_reached}}
    <span
      class="badge badge-notification"
      title={{i18n "discourse_topic_management.topic_list.limit_reached.title"}}
      style="color: var({{this.siteSettings.discourse_topic_notification_color}}); background-color: var({{this.siteSettings.discourse_topic_notification_background_color}})"
    >
      {{i18n "discourse_topic_management.topic_list.limit_reached.message"}}
    </span>
  {{/if}}
  */
  {
    "id": "2R54kV08",
    "block": "[[[41,[30,0,[\"topic\",\"limit_reached\"]],[[[1,\"  \"],[10,1],[14,0,\"badge badge-notification\"],[15,\"title\",[28,[37,2],[\"discourse_topic_management.topic_list.limit_reached.title\"],null]],[15,5,[29,[\"color: var(\",[30,0,[\"siteSettings\",\"discourse_topic_notification_color\"]],\"); background-color: var(\",[30,0,[\"siteSettings\",\"discourse_topic_notification_background_color\"]],\")\"]]],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"discourse_topic_management.topic_list.limit_reached.message\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"span\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-topic-management/discourse/templates/connectors/topic-list-after-title/topic.hbs",
    "isStrictMode": false
  });
});