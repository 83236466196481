define("discourse/plugins/discourse-topic-management/discourse/archived-topics-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "user",
    map() {
      this.route("archived-topics", {
        resetNamespace: true
      });
    }
  };
});