define("discourse/plugins/discourse-topic-management/discourse/templates/archived-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="post-list user-stream">
    {{#each this.archivedTopics.archived_topics as |topic|}}
      <div class="post-list-item user-stream-item">
        <div class="post-list-item__header info">
          <div class="post-list-item__details">
            <div class="stream-topic-title">
              <span class="title">
  
                <LinkTo @route="topic" @models={{array topic.[5] topic.[0]}}>
                  {{topic.[1]}}
                </LinkTo>
              </span>
            </div>
            <div class="post-list-item__metadata">
              <span class="time">
                {{format-date topic.[4]}}
  
              </span>
            </div>
          </div>
        </div>
        <div data-topic-id={{topic.[0]}}>
          <div class="cooked">{{replace-emoji (html-safe topic.[2])}}</div>
        </div>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "XC9FyRDA",
    "block": "[[[10,0],[14,0,\"post-list user-stream\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"archivedTopics\",\"archived_topics\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"post-list-item user-stream-item\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"post-list-item__header info\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"post-list-item__details\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"stream-topic-title\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"title\"],[12],[1,\"\\n\\n              \"],[8,[39,4],null,[[\"@route\",\"@models\"],[\"topic\",[28,[37,5],[[30,1,[\"5\"]],[30,1,[\"0\"]]],null]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,1,[\"1\"]]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"post-list-item__metadata\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"time\"],[12],[1,\"\\n              \"],[1,[28,[35,6],[[30,1,[\"4\"]]],null]],[1,\"\\n\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[15,\"data-topic-id\",[30,1,[\"0\"]]],[12],[1,\"\\n        \"],[10,0],[14,0,\"cooked\"],[12],[1,[28,[35,7],[[28,[37,8],[[30,1,[\"2\"]]],null]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"topic\"],false,[\"div\",\"each\",\"-track-array\",\"span\",\"link-to\",\"array\",\"format-date\",\"replace-emoji\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-topic-management/discourse/templates/archived-topics.hbs",
    "isStrictMode": false
  });
});