define("discourse/plugins/discourse-topic-management/discourse/templates/connectors/user-main-nav/user-archived-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DNavigationItem @route="archived-topics" class="user-nav__summary">
    {{d-icon "box-archive"}}
    <span class="rating-review-tab">Archived Topics</span>
  </DNavigationItem>
  */
  {
    "id": "2OnyUBRq",
    "block": "[[[8,[39,0],[[24,0,\"user-nav__summary\"]],[[\"@route\"],[\"archived-topics\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[\"box-archive\"],null]],[1,\"\\n  \"],[10,1],[14,0,\"rating-review-tab\"],[12],[1,\"Archived Topics\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"d-navigation-item\",\"d-icon\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-topic-management/discourse/templates/connectors/user-main-nav/user-archived-topics.hbs",
    "isStrictMode": false
  });
});