define("discourse/plugins/discourse-topic-management/discourse/templates/connectors/user-menu-tabs-list__after/cta-archived", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="bottom-tabs tabs-list">
    <LinkTo
      @route="archived-topics"
      @model={{this.user}}
      class="btn btn-flat btn-icon no-text user-menu-tab"
      title={{i18n "discourse_topic_management.archived_topics.title"}}
    >
      {{d-icon "box-archive"}}
    </LinkTo>
  </div>
  */
  {
    "id": "hjdskRGN",
    "block": "[[[10,0],[14,0,\"bottom-tabs tabs-list\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn btn-flat btn-icon no-text user-menu-tab\"],[16,\"title\",[28,[37,2],[\"discourse_topic_management.archived_topics.title\"],null]]],[[\"@route\",\"@model\"],[\"archived-topics\",[30,0,[\"user\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"box-archive\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"link-to\",\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-topic-management/discourse/templates/connectors/user-menu-tabs-list__after/cta-archived.hbs",
    "isStrictMode": false
  });
});