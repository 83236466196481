define("discourse/plugins/discourse-topic-management/discourse/models/archived-topics", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service"], function (_exports, _object, _ajax, _ajaxError, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ArchivedTopics = _object.default.extend({});
  ArchivedTopics.reopenClass({
    list(username) {
      const currentUser = this.getCurrentUser();
      if (!currentUser || !currentUser.staff && currentUser.username !== username) {
        return ["No"];
      }
      return (0, _ajax.ajax)(`/u/${username}/archived-topics.json`).then(_ref => {
        let {
          archived_topics
        } = _ref;
        return archived_topics;
      }).catch(_ajaxError.popupAjaxError);
    },
    getCurrentUser() {
      return Discourse.__container__.lookup("service:current-user");
    }
  });
  var _default = _exports.default = ArchivedTopics;
});