define("discourse/plugins/discourse-topic-management/discourse/controllers/archived-topics", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    archivedTopics: null,
    init() {
      this._super(...arguments);
    }
  });
});